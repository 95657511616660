export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~9],
		"/about": [10,[2]],
		"/account": [11,[3]],
		"/account/contact": [12,[3]],
		"/account/games": [13,[3]],
		"/account/password": [14,[3]],
		"/account/subscription": [15,[3]],
		"/auth/login": [~16,[4]],
		"/auth/logout": [~17,[4]],
		"/auth/redirect": [~18,[4]],
		"/auth/register": [~19,[4]],
		"/auth/unlock": [~20,[4]],
		"/contact": [21,[5]],
		"/developer": [22],
		"/faq": [23,[6]],
		"/lab": [24],
		"/legal": [25,[7]],
		"/legal/privacy": [26,[7]],
		"/legal/terms": [27,[7]],
		"/play/[game]": [~28,[8]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';